import React from 'react';
import { Shadow } from './styles'

const GalleryHud: React.FC = () => {

  return (
    <Shadow />
  )
}

export default GalleryHud;